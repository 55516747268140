:root{
    --primary-beige: #fcdebe;
    --primary-dark: rgba(255, 255, 255, 0);
}

#kn-logo {
    height: 5rem;
    width: 5rem;
    border-radius: 40px;
}

.navbar-brand {
    padding: 0.625rem;
    margin-left: 1.875rem;
}

.navbar.active {
    background-color: rgb(247, 213, 213);
}

nav {
    font-family: 'Nunito', sans-serif;
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important; 
}

nav a.nav-link {
    letter-spacing: 0.03rem;
    color: white;
    font-weight: 500;
    font-size: 1.2rem;
}

.navbar-nav {
    padding-right: 2.5rem;
}

.navbar-nav > li > a {
    margin-right: 0.75rem;
    border-bottom: 0.1975rem solid var(--primary-dark);
}

.navbar-nav > li > a.active, .navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
    border-bottom: 0.1975rem solid var(--primary-beige);
    color: rgb(235, 234, 204);
}

.collapse-wraper {
    text-align: right;
}

@media only screen and (max-width: 600px){
    #kn-logo {
        height: 3rem;
        width: 3rem;
        border-radius: 3rem;
    }

    .navbar-brand {
        padding: 0;
        margin-left: 0;
    }

    .nav-link {
        font-size: 1rem !important;
    }
}