@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@600&family=Nunito:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&family=Raleway:wght@600&display=swap');

.App {
  font-family: 'Montserrat', sans-serif;
  position: relative;
  background: url("../img/lake-tahoe-1.jpeg") no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 10rem 20.5rem rgba(0, 0, 0, 0.301);
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html {
  scroll-behavior: smooth;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
