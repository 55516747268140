#work {
    padding: 8rem;
    background-color: rgb(240, 240, 239);
    align-items: center;
}

#my-work {
    text-align: center;
    padding-bottom: 1rem;
}

.container {
    position: relative;
    width: 80%;
}

.image {
    display: block;
    width: 100%;
    height: auto;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.overlay {
    position: absolute;
    border-radius: 10px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
}

#makegotchi {
    background-color: #d3bfe8e2;
}

#hipstew {
    background-color: #bfb29ee3;
}

#petopia {
    background-color: #ca889bde;
}

h2 {
    font-family: 'Comfortaa', cursive;
}

.container:hover .overlay {
    opacity: 1;
}

.text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

@media only screen and (max-width: 1450px) {
    #work {
        padding: 1rem 0 1rem 0;
    }

    .container {
        width: 90%;
    }

    .text {
        color: white;
        font-size: 1.2rem;
        top: 50%;
        left: 50%;
        text-align: center;
        padding: 2rem;
        transform: none;
        position: unset;
    }

    .overlay {
        overflow-y: auto;
    }
}



@media only screen and (max-width: 600px){
    #work {
        padding: 1rem 0 1rem 0;
    }

    .container {
        width: 90%;
    }

    h2 {
        font-size: 1rem;
    }

    p {
        font-size: 0.5rem;
    }

    .text {
        color: white;
        font-size: 30px;
        top: 50%;
        left: 50%;
        text-align: center;
        padding: 1rem;
    }

    .overlay {
        overflow-y: scroll;
    }
}