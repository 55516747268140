h1 {
    font-family: 'Raleway', sans-serif;
    letter-spacing: 0.05rem;
}

#header-content {
    color: rgb(255, 255, 255);
    font-size: 5rem;
    padding-top: 10rem;
}

.header-script {
    font-size: 3rem;
    color: rgb(255, 255, 255);
}

.header-wraper {
    height: 100vh;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    #header-content {
        color: rgb(255, 255, 255);
        font-size: 3rem;
        padding-top: 10rem;
    }

    .header-script {
        font-size: 2rem;
        color: rgb(255, 255, 255);
    }
}