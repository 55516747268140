.form-wraper {
    padding: 5rem 25rem 5rem 25rem;
    position: relative;
    background: url("../img/lake-tahoe.jpeg") no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
}

.form-info {
    background-color: rgba(255, 255, 255, 0.81);
    border-radius: 20px;
    padding: 2rem 5rem;
}

.submit-btn {
    transition: 300ms linear;
    background: #ffffffc1 !important;
    border-radius: 1.5rem;
    border-color: #f24e4ea3 !important;
    border: 0.2rem solid;
    color: #f24e4ea3;
    padding-left: 1rem;
    padding-right: 1rem;
}

button:hover {
    transform: scale(1.1);
    background: #f24e4ea3 !important;
}

.customer-input {
    border-radius: 0;
    background-color: transparent;
    border: none;
    border-bottom: 0.11rem solid #a5b8e6;
}

#name-email{
    display: flex;
}

@media only screen and (max-width: 1200px) {
    .form-wraper {
        padding: 0;
    }

    .form-info {
        background-color: #ffffffcf;
        border-radius: 20px;
        padding: 2rem 3rem;
        width: 80vw;
        display: inline-block !important;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}