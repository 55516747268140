@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cabin:wght@600&family=Nunito:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&family=Raleway:wght@600&display=swap);
.App {
  font-family: 'Montserrat', sans-serif;
  position: relative;
  background: url(/static/media/lake-tahoe-1.be1e095f.jpeg) no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 10rem 20.5rem rgba(0, 0, 0, 0.301);
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html {
  scroll-behavior: smooth;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root{
    --primary-beige: #fcdebe;
    --primary-dark: rgba(255, 255, 255, 0);
}

#kn-logo {
    height: 5rem;
    width: 5rem;
    border-radius: 40px;
}

.navbar-brand {
    padding: 0.625rem;
    margin-left: 1.875rem;
}

.navbar.active {
    background-color: rgb(247, 213, 213);
}

nav {
    font-family: 'Nunito', sans-serif;
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important; 
}

nav a.nav-link {
    letter-spacing: 0.03rem;
    color: white;
    font-weight: 500;
    font-size: 1.2rem;
}

.navbar-nav {
    padding-right: 2.5rem;
}

.navbar-nav > li > a {
    margin-right: 0.75rem;
    border-bottom: 0.1975rem solid rgba(255, 255, 255, 0);
    border-bottom: 0.1975rem solid var(--primary-dark);
}

.navbar-nav > li > a.active, .navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
    border-bottom: 0.1975rem solid #fcdebe;
    border-bottom: 0.1975rem solid var(--primary-beige);
    color: rgb(235, 234, 204);
}

.collapse-wraper {
    text-align: right;
}

@media only screen and (max-width: 600px){
    #kn-logo {
        height: 3rem;
        width: 3rem;
        border-radius: 3rem;
    }

    .navbar-brand {
        padding: 0;
        margin-left: 0;
    }

    .nav-link {
        font-size: 1rem !important;
    }
}
h1 {
    font-family: 'Raleway', sans-serif;
    letter-spacing: 0.05rem;
}

#header-content {
    color: rgb(255, 255, 255);
    font-size: 5rem;
    padding-top: 10rem;
}

.header-script {
    font-size: 3rem;
    color: rgb(255, 255, 255);
}

.header-wraper {
    height: 100vh;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    #header-content {
        color: rgb(255, 255, 255);
        font-size: 3rem;
        padding-top: 10rem;
    }

    .header-script {
        font-size: 2rem;
        color: rgb(255, 255, 255);
    }
}
.bio-pic {
    width: 25.125rem;
    height: 26.375rem;
    border-radius: 50%;
}

.second-bio-pic {
    display: none;
}

.bio-wraper {
    padding: 5rem 8rem;
    background-color: rgb(43, 43, 43);
    color: white;
}

.bio-pic1 {
    width: 28%;
    height: 32rem;
    border: 0.5rem solid white;
}

.pic-wraper {
    text-align: center;
    padding-top: 4rem;
}

.resume-file{
    color: black;
}

.social-media {
    padding-right: 1rem;
    padding-left: 1rem;
    font-weight: bold;
    color: #df744a;
    letter-spacing: 0.05rem;
}

.social-wraper {
    text-align: center;
    padding-top: 2rem;
}

.social-media:hover, .social-media:focus {
    color: #df977b;
    text-decoration: none;
    border-bottom: solid #df977b;
}

@media only screen and (max-width: 1050px) {
    .bio-pic {
        width: 21rem;
        height: 22rem;
        border-radius: 50%;
        padding: 1rem;
    }
    
    .bio-wraper {
        padding: 1rem 3rem;
        background-color: rgb(43, 43, 43);
        color: white;
    }

    .pic-wraper {
        text-align: center;
        padding-top: 4rem;
    }

    .bio-pic1 {
        width: 80%;
        height: 80%;
        border: 1rem solid white;
    }

    .media-body {
        width: 50vw;
    }

    .social-wraper {
        display: inline-grid;
        text-align: left;
    }

    .social-media {
        letter-spacing: 0.05rem;
        padding-right: 0.3rem;
    }
}

@media only screen and (max-width: 900px) {
    .bio-pic {
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
    }

    .bio-wraper {
        padding: 1rem 3rem;
        background-color: rgb(43, 43, 43);
        color: white;
    }

    #bio-content {
        font-size: 0.8rem !important;
    }

    .pic-wraper {
        text-align: center;
        padding-top: 4rem;
    }

    .bio-pic1 {
        width: 100%;
        height: 100%;
        border: 0.5rem solid white;
    }

    .bio-header, #my-work, #form-header {
        font-size: 2rem !important;
    }

    .media-body {
        width: 50vw;
    }

    .social-wraper {
        display: inline-grid;
        text-align: left;
    }

    .social-media {
        letter-spacing: 0.05rem;
        padding: 0;
    }
}

@media only screen and (max-width: 300px){
    .bio-pic {
        display: none;
    }

    .second-bio-pic {
        display: flex;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
    }
}

#work {
    padding: 8rem;
    background-color: rgb(240, 240, 239);
    align-items: center;
}

#my-work {
    text-align: center;
    padding-bottom: 1rem;
}

.container {
    position: relative;
    width: 80%;
}

.image {
    display: block;
    width: 100%;
    height: auto;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.overlay {
    position: absolute;
    border-radius: 10px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
}

#makegotchi {
    background-color: #d3bfe8e2;
}

#hipstew {
    background-color: #bfb29ee3;
}

#petopia {
    background-color: #ca889bde;
}

h2 {
    font-family: 'Comfortaa', cursive;
}

.container:hover .overlay {
    opacity: 1;
}

.text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

@media only screen and (max-width: 1450px) {
    #work {
        padding: 1rem 0 1rem 0;
    }

    .container {
        width: 90%;
    }

    .text {
        color: white;
        font-size: 1.2rem;
        top: 50%;
        left: 50%;
        text-align: center;
        padding: 2rem;
        transform: none;
        position: unset;
    }

    .overlay {
        overflow-y: auto;
    }
}



@media only screen and (max-width: 600px){
    #work {
        padding: 1rem 0 1rem 0;
    }

    .container {
        width: 90%;
    }

    h2 {
        font-size: 1rem;
    }

    p {
        font-size: 0.5rem;
    }

    .text {
        color: white;
        font-size: 30px;
        top: 50%;
        left: 50%;
        text-align: center;
        padding: 1rem;
    }

    .overlay {
        overflow-y: scroll;
    }
}
.form-wraper {
    padding: 5rem 25rem 5rem 25rem;
    position: relative;
    background: url(/static/media/lake-tahoe.73bcb029.jpeg) no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
}

.form-info {
    background-color: rgba(255, 255, 255, 0.81);
    border-radius: 20px;
    padding: 2rem 5rem;
}

.submit-btn {
    transition: 300ms linear;
    background: #ffffffc1 !important;
    border-radius: 1.5rem;
    border-color: #f24e4ea3 !important;
    border: 0.2rem solid;
    color: #f24e4ea3;
    padding-left: 1rem;
    padding-right: 1rem;
}

button:hover {
    transform: scale(1.1);
    background: #f24e4ea3 !important;
}

.customer-input {
    border-radius: 0;
    background-color: transparent;
    border: none;
    border-bottom: 0.11rem solid #a5b8e6;
}

#name-email{
    display: flex;
}

@media only screen and (max-width: 1200px) {
    .form-wraper {
        padding: 0;
    }

    .form-info {
        background-color: #ffffffcf;
        border-radius: 20px;
        padding: 2rem 3rem;
        width: 80vw;
        display: inline-block !important;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}
.card-wraper {
    height: auto;
    background-color: white;
}
